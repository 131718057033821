<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="时间">
              <a-range-picker @change="time" format="YYYY-MM-DD HH:mm:ss" style="width: 100%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.BillStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator" v-if="false">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 160px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)">详情</a>
          <a-divider type="vertical" v-if="record.status === 1"/>
          <a-popconfirm
            title="确定要支付么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleUpdate(record)"
            v-if="record.status === 1"
          >
            <a>支付</a>
          </a-popconfirm>
          <!--          <a-divider type="vertical" v-if="record.status === 1"/>-->
          <!--          <a-popconfirm-->
          <!--            v-if="record.status === 1"-->
          <!--            title="确定要删除么？"-->
          <!--            ok-text="确定"-->
          <!--            cancel-text="取消"-->
          <!--            @confirm="handleDelet(record)"-->
          <!--          >-->
          <!--            <a>删除</a>-->
          <!--          </a-popconfirm>-->
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <create-pay-form
      ref="createPayModal"
      v-if="payvisible"
      :visible="payvisible"
      :loading="payconfirmLoading"
      :model="paymdl"
      @cancel="handlePayCancel"
      @ok="handlePayOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import CreatePayForm from '../modules/CreatePayForm'

import {
  electricity_bill_create,
  electricity_bill_delete,
  electricity_bill_list
} from '@/api/electricity'
import { payment_ticket_create } from '@/api/payment_ticket'
import { property_bill_payment } from '@/api/property_bill'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    CreatePayForm
  },
  data () {
    return {
      paying: false,
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '企业',
          dataIndex: 'corporation'
        },
        {
          title: '充值金额(元)',
          align: 'center',
          dataIndex: 'total_payment',
          scopedSlots: { customRender: 'money_render' }
        },
        // {
        //   title: '实际付款(元)',
        //   dataIndex: 'actual_payment',
        //   align: 'center',
        //   scopedSlots: { customRender: 'money_render' },
        //   ellipsis: true
        // },
        {
          title: '账户',
          dataIndex: 'property_owner',
          customRender: (text) => '*' + this.$Dictionaries.property_owner[text].substr(this.$Dictionaries.property_owner[text].length - 4, this.$Dictionaries.property_owner[text]),
          align: 'center',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          customRender: (text) => this.$Dictionaries.BillStatus[text]
        },
        {
          title: '付款时间',
          dataIndex: 'actual_payment_time',
          customRender: (text) => (text === '1970-01-01 00:00:00' && '--') || text,
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('电费参数', parameter)
        return electricity_bill_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDetail (record) {
      this.$router.push({ path: '/electricity_record/' + record.id })
    },
    handleUpdate (record) {
      console.log(record)
      // this.current_bill_id = record.id
      // this.paymdl = record
      if (this.paying) {
        this.$message.warning('支付请求提交中,请勿重复提交')
        return
      }
      this.paying = true
      property_bill_payment({ bill_id: record.id, category: 4 }).then(res => {
        console.log(res)
        this.$refs.table.refresh(true)
      }).finally(() => {
        this.paying = false
      })
    },
    handleDelet (record) {
      electricity_bill_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.corporation_id = this.$Dictionaries.corporation_id
          values.total_payment = Math.round(values.total_payment * 100)
          values.amount = Math.round(values.amount * 100)
          // 新增
          electricity_bill_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handlePayOk () {
      const form = this.$refs.createPayModal.form
      this.payconfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.amount = Math.round(values.amount * 100)
          const param = Object.assign({}, values, { corporation_id: this.$Dictionaries.corporation_id, bill_id: this.current_bill_id, category: 4 })
          // 新增
          payment_ticket_create(param).then(res => {
            this.payvisible = false
            this.payconfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
        } else {
          this.payconfirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
